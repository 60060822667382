const paragraphControls = document.querySelectorAll('.content-truncation .paragraph-controller');
paragraphControls.forEach((expandControl) => {
    ['click', 'keypress'].forEach((event) => {
        expandControl.addEventListener(event, (e) => {
            if (event === 'click' || e.keyCode === 13) {
                const parent = document.getElementById(e.target.getAttribute('aria-controls'));
                const expansion = parent.getAttribute('aria-expanded');
                if (expansion === 'false') {
                    parent.setAttribute('aria-expanded', 'true');
                } else {
                    parent.setAttribute('aria-expanded', 'false');
                }
            }
        });
    });
});
